$(document).ready(function() {

  	$(document).on('click', 'a[href^="#"]', function (event) {
  		event.preventDefault();
  		$('html, body').animate({
  			scrollTop: $($.attr(this, 'href')).offset().top - 47
  		}, 250);
  	});

    // Cache selectors
    var lastId,
        topMenu = $(".navbar"),
        topMenuHeight = topMenu.outerHeight()+15,
        // All list items
        menuItems = topMenu.find("a.nav-link"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
          var item = $($(this).attr("href"));
          if (item.length) { return item; }
        });

    // Bind click handler to menu items
    // so we can get a fancy scroll animation
    menuItems.click(function(e){
      var href = $(this).attr("href"),
          offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
      $('html, body').stop().animate({ 
          scrollTop: offsetTop
      }, 300);
      e.preventDefault();
    });

    // Bind to scroll
    $(window).scroll(function(){
       // Get container scroll position
       var fromTop = $(this).scrollTop()+topMenuHeight;
       
       // Get id of current scroll item
       var cur = scrollItems.map(function(){
         if ($(this).offset().top < fromTop)
           return this;
       });
       // Get the id of the current element
       cur = cur[cur.length-1];
       var id = cur && cur.length ? cur[0].id : "";
       
       if (lastId !== id) {
           lastId = id;
           // Set/remove active class
           menuItems
             .parent().removeClass("active")
             .end().filter("[href='#"+id+"']").parent().addClass("active");
       }                   
    });

    $(window).scroll(function(){
        if ( $(this).scrollTop() > 120 ) {
            $('.navbar').fadeIn();
        } else if ( $(this).scrollTop() <= 120 ) {
            $('.navbar').fadeOut();
        }
    });

    var data = [ 'Баскаки', 'Берестяні грамоти', 'Билини (старини)', 'Бояри', 'Вотчина', 'Гарем', 'Генуезці', 'Городище', 'Готи', 'Графіті', 'Гридниця', 'Гридь', 'Гуни', 'Гуральня', 'Данина', 'Дитинець', 'Дружина', 'Закріпачення', 'Звичаєве право', 'Ігумен', 'Ікона', 'Імперія', 'Каган', 'Каганат', 'Книжковий ініціал', 'Буквиця', 'Князь', 'Вождь', 'Кримські татари', 'Куна', 'Лихварство', 'Літопис', 'Магдебурзьке право', 'Магнат', 'Медресе', 'Мечеть', 'Митрополит', 'Мінарет', 'Мозаїка', 'Молитва', 'Монархія', 'Монастир', 'Монотеїзм', 'Монументальне мистецтво', 'Орда', 'Печеніги', 'Політична унія', 'Половці', 'Полюддя', 'Порок', 'Поруб', 'Сейм', 'Синагога', 'Султан', 'Тамга', 'Тріумвірат', 'Уділ', 'Удільна', 'Уніфікація', 'Усобиця', 'Федерація', 'Фільварок', 'Фреска', 'Церква', 'Цех', 'Ченці', 'Шлюбна дипломатія', 'Шляхта', 'Язичництво', 'Яничари', 'Ярлик', 'Ясир'];
    $(function() {
      $("input").autocomplete({
        source:[data]
      }); 
    });

    $("#search").keyup(function(){
        _this = this;
        $.each($(".main p"), function() {
            if($(this).text().toLowerCase().indexOf($(_this).val().toLowerCase()) === -1) {
               $(this).hide();
               $('h2').hide();
               $('hr').hide();
             } else {
               $(this).show();
               $('h2').show();
               $('hr').show();
               }                
        });
    });
    $('#reset').click(function(){
      location.reload();
    })
});